export const contacts = [
  "belen.lopezdupuy@lbo.com.ar",
  "cintia.majul@lbo.com.ar",
  "luciano.cocconi@lbo.com.ar",
  "ignacio.cueva@lbo.com.ar",
  "maricel.rivero@lbo.com.ar",
  "fabricio.silvestri@lbo.com.ar",
  "federico.epstein@lbo.com.ar",
  "juancarlos.silvestri@lbo.com.ar",
  "mario.cutruneo@lbo.com.ar",
  "florencia.santi@lbo.com.ar",
  "manuel.asenjo@lbo.com.ar",
  "mauro.lopez@lbo.com.ar",
  "victoria.frances@lbo.com.ar",
  "ignacio.pagliano@lbo.com.ar",
  "andrea.romano@lbo.com.ar",
  "juan.bernardez@lbo.com.ar",
  "martin.velis@lbo.com.ar",
  "paola.echenique@lbo.com.ar",
  "martin.bo@lbo.com.ar",
].sort();
